// App.js
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalProvider, useGlobal } from './context/GlobalContext';
import Cookies from 'js-cookie';
import UserService from './services/User';
import CuerpoService from './services/Cuerpo';

const LandingPage = lazy(() => import('./components/LandingPage'));
const CuerpoSelector = lazy(() => import('./components/Cuerpos'));
const AppRoutes = lazy(() => import('./components/AppRoutes'));

const AppContent = () => {
  const { state, dispatch } = useGlobal();
  const [isReady, setIsReady] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const initApp = async () => {
      const cookieUsuario = Cookies.get('usuario');
      if (!cookieUsuario) {
        setIsReady(true);
        return;
      }

      const datosUsuario = JSON.parse(cookieUsuario);
      setToken(datosUsuario.token);

      try {
        // Obtener datos del usuario
        const fetchedUser = await UserService.getUserById(datosUsuario.id);

        if (fetchedUser) {
          dispatch({ type: 'SET_USER', payload: fetchedUser });

          // Si el usuario tiene cuerpo, obtener datos del cuerpo en paralelo
          if (fetchedUser.cuerpo) {
            const [fetchedCuerpo] = await Promise.all([
              CuerpoService.getCuerpoById(fetchedUser.cuerpo),
              // Puedes agregar más llamadas en paralelo aquí
            ]);
            dispatch({ type: 'SET_CUERPO', payload: fetchedCuerpo });
          }
        }
      } catch (error) {
        console.error('Error al inicializar la aplicación:', error);
        // Manejar errores si es necesario
      } finally {
        setIsReady(true);
      }
    };

    initApp();
  }, [dispatch]);

  if (!isReady) {
    // Efecto de carga similar al componente Roadmap
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="space-y-6 w-full max-w-md px-4">
          <div className="h-8 bg-gray-200 rounded w-3/4 mx-auto animate-pulse" />
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 rounded w-full animate-pulse" />
            <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse" />
            <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse" />
          </div>
          <div className="flex space-x-4 mt-8">
            <div className="h-12 w-12 bg-gray-200 rounded-full animate-pulse" />
            <div className="flex-1 space-y-4">
              <div className="h-4 bg-gray-200 rounded w-5/6 animate-pulse" />
              <div className="h-4 bg-gray-200 rounded w-4/6 animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Suspense fallback={null}>
      {!state.user || !token ? (
        <LandingPage />
      ) : !state.user.cuerpo ? (
        <CuerpoSelector />
      ) : (
        <Router>
          <AppRoutes />
        </Router>
      )}
    </Suspense>
  );
};

const App = () => {
  return (
    <GlobalProvider>
      <AppContent />
    </GlobalProvider>
  );
};

export default App;
